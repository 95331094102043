import logo from './images/Cowboy-Mascot-Final.png';
import header from './images/StraightenerHeader.png';
import appstore from './images/AppStore.png';
import './App.css';
import PrivacyPolicy from './PrivacyPolicy.pdf';
import UserAgreement from './UserAgreement.pdf';

function showStuff(id) {
  if(document.getElementById(id).style.display == 'block'){
    document.getElementById(id).style.display = 'none';
  }
  else {
    document.getElementById(id).style.display = 'block';
  }
}

function App() {

  return (
    <div className="App">
      <header className="App-header">
        <img src={header} style={{margin: 10}} className="Header-image" alt="header" />
      </header>
      <div className="body">
        <p style={{margin: 30, textAlign: 'left'}}>
          Straightener is a new innovative social app founded from the idea of having the ability to anonymously ask questions to people of your choosing and receive quality answers that are private to you and the other person. This idea is possible due to the incentive system of rating answers you receive back as the anonymous user on a scale from one to five stars. Based on the star rating you give, the person who answered your question will receive a certain amount of the in-app currency “arrows,” and when you earn enough arrows, you can cash out on a gift card of your choosing inside the app.
        </p>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Live on the App Store now!
        </p>
        <img src={appstore} className="App-store" alt="store" style={{marginBottom: 30}}/>
        <div className="App-footer" style={{padding: 30}}>
          <a href = {UserAgreement} target = "_blank" >Terms of Service</a>
          <a href = {PrivacyPolicy} target = "_blank" style={{margin: 10}}>Privacy Policy</a>
          <a href="#!" onClick={() => showStuff("aboutText")}>About Us</a>
          <p id="aboutText" style={{marginTop: 10, marginBottom: 50}}>CEO/Founder: Kami Nader <br/><br/>
            Born in Missouri City, Texas, Kami attended The Kinkaid School during his high school years. After Highschool, Kami attended Suffield Academy in Suffield, Connecticut, for a Postgraduate year in 2020. Shortly after, Kami enrolled at Trinity College in Hartford, Connecticut, to play football and receive a college degree as a four-year plan. However, in the summer before his enrollment to Trinity College, Kami had decided to pursue the idea he had for Straightener and assembled the best team possible to pursue this dream while heading to college. Kami reconnected with his former classmate at The Kinkaid School, Kirby Cravens, and from there, the development of Straightener went from sketches in a notebook to reality. Kami is currently a sophomore at Trinity College at this posting.
            <br/><br/>
            CTO/Founder: Kirby Cravens <br/><br/>
            Kirby graduated from The Kinkaid School in 2017 and from Southern Methodist University in 2021. It was at SMU where he received a Bachelor of Science in Computer Science and realized his interest in software development.  Straightener is Kirby's first project and he has taken on the role of the sole developer for the application.
          </p>
        </div>
      </div>
    </div>
  );
}
// https://apps.apple.com/us/app/straightener/id1576749812?platform=iphone

export default App;
